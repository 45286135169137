/* .text {
    color: #ff0000;
    font-family: system-ui;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    text-shadow: 0 1px 1px #fff;
}

.footer {
    bottom: 5%;
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
}

.rotate {
    transform: rotate(-5deg);
}

#sticker {
    width: 100%;
    max-width: 400px;
    height: auto;
}
.splash_poster{
    width: 100%;
    max-width: 300px;
    height: auto;
    max-height: 500px;
    border-radius: 5px;
}
.title-text{
    color:black;
    text-align: center;
    font-family: 'Be Vietnam Pro', sans-serif;
}
 */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");
body {
  margin: 0;
  font-family: "Poppins", "Roboto", "Ubuntu", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
  overflow-x: hidden;
  background-color: #fff;
}
input:active {
  outline: none;
  border: none;
}
input:focus{
  outline: 1px solid grey;
}
.App {
  /* max-width: 500px; */
  background-image: linear-gradient(to bottom, #0059b3, #001933);
  /* background: url("../assets/love/window.png") no-repeat center center fixed;  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
}

.logo {
  width: 100px; /* Adjust size as needed */
  height: auto; /* To maintain aspect ratio */
  position: absolute;
  top: 1%;
  left: 4%;
}

.rocketlogo {
  position: absolute;
  bottom: -120px; /* Adjust as needed */
  right: -30px; /* Adjust as needed */
  width: 100px;
}
.continueButton{
  background-image: linear-gradient(60deg, #6c2bc0 10%, #3f86e8 100%);
  color: black; /* White text color */
  padding: 20px 40px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  margin-top: 20px; 
  z-index: 1;
}
.cancel{
  background-color:red ;
  margin-top: 10px;
  color: black; /* White text color */
  padding: 8px 40px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  z-index: 1;
}

.Glass {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow-y: "auto";
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: baseline;
  margin: 3% 5%;
}

.VideoFrame {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1vw;
}

.embedVideo {
  width: "450px";
  max-width: "80vh";
  max-height: "70vh";
}

.Glass h1 {
  margin-top: 20px;
  color: white;
  font-size: 30px;
  align-items: flex-start ;
}
.Glass p {
  color: white;
  font-size: 20px;
  text-align: left;
  margin: 1% 2%;
  /* align-items: last baseline; */
}
.splashButton {
  padding: 10px;
  border-radius: 200px;
  background-color: #fd4a5d;
  color: white;
  text-align: center;
  font-weight: bold;
  margin: 10px;
}
.lastcontainer h6, p {
  color: black;
}

.lastcontainer p {
  font-weight: bold;
  font-size: 15px;
}
.TryButton {
  background-image: linear-gradient(60deg, #6c2bc0 10%, #3f86e8 100%);
  color: white;
  padding: 10px 20px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  margin-bottom: 50px;
}

/* button css */

.front {
  display: block;
  padding: 20px 80px;
  border-radius: 12px;
  font-size: 1.55rem;
  background: hsl(345deg 100% 47%);
  color: white;
  transform: translateY(-6px);
}
.strip {
  background-color:  rgba(0, 0, 0, 0.3);
}
/* -------------- */

/* star picture */
.star {
  position: absolute;
  margin-top: -10%;
  left: 45%;
  width: 80px;
}
.star1 {
  position: absolute;
  margin-top: -7%;
  z-index: 1000;
}

/* ------------------------- */

/* thumbnail */

.thumb {
  width: 400px;
  overflow: hidden;
}

/* tagline */

.tag {
  font-weight: 500;
  color: white;
  /* margin-top: -10px; */
}


/* share button */

.container-btn {
  flex: 1; /* Take up remaining vertical space */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: flex-end; /* Align content to the bottom */
}


.btn_wrap {
  position: fixed; /* Fixed position for sticking to bottom */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  bottom: 50px; /* Adjust as needed */

  display: -webkit-box;
  display: -ms-flexbox;
  
  -webkit-box-pack: center;
      -ms-flex-pack: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
  overflow: hidden;
  cursor: pointer;
  width: 80%;
  height: 72px;
  background-color: #EEEEED;
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
          transform: scale(1.1)
}

.btn-sr{
  position: fixed;
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */  border-radius: 80px;
  font-size: 20px;
  text-align: center;
  line-height: 70px;
  letter-spacing: 2px;
  color: #EEEEED;
  background-color: red;
  padding: 0 18px;
  -webkit-transition: all 1.2s ease;
  line-height: 72px; /* Center text vertically */

  transition: all 1.2s ease;
}


.btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
          width: 100%; /* Full width of the container */
          height: 64px;
  border-radius: 80px;
}

.btn-container i:nth-of-type(1) {
          -webkit-transition-delay: 1.1s;
                  transition-delay: 1.1s;
}

.btn-container i:nth-of-type(2) {
          -webkit-transition-delay: .9s;
                  transition-delay: .9s;
}

.btn-container i:nth-of-type(3) {
          -webkit-transition-delay: .7s;
                  transition-delay: .7s;
}

.btn-container i:nth-of-type(4) {
          -webkit-transition-delay: .4s;
                  transition-delay: .4s;
}
.btn-container i:nth-of-type(5) {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}
.btn_wrap:hover span {
  -webkit-transition-delay: .25s;
          transition-delay: .25s;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%)
}

.btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}



@media (max-width: 1200px) {

  .Glass {
    margin-top: 12%;
  }
  .rocketlogo {
    position: absolute;
    bottom: -120px; /* Adjust as needed */
    right: -30px; /* Adjust as needed */
    width: 90px;
  }

}

@media (max-width: 1383px) {
  .tag {
    margin-top: -1px;
  }
}

@media (max-width: 946px) {

  .Glass {
    margin-top: 15%;
  }
  
  .Glass h1 {
    margin-top: 20px;
    color: white;
    font-size: 20px;
    align-items: last baseline;
  }
  .Glass p {
    color: white;
    font-size: 15px;
    text-align: left;
    margin: 1% 2%;
    align-items: last baseline;
  }
  .front {
    padding: 10px 60px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 12px 30px;
  }
  .spacing {
    margin-right: 160px;
  }
  .rocketlogo {
    position: absolute;
    bottom: -110px; /* Adjust as needed */
    right: -30px; /* Adjust as needed */
    width: 80px;
  }
  .star {
    margin-top: -22%;
  }
  .star1 {
    margin-top: -9%;
  }
}
/* @media (max-width: 810px) {
  .Glass h1,h2 {
    margin-top: 20px;
    color: white;
    font-size: 40px;
    text-align: start;
  }
  .Glass p {
    color: white;
    font-size: 20px;
    text-align: start;
    margin: 2% 2%;
  }
  .rocketlogo {
    position: absolute;
    bottom: -120px;
    right: -30px; 
    width: 100px;
  }
} */
@media (max-width: 781px) {
  .Glass {
    margin-top: 20%;
  }
  .front {
    padding: 8px 40px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 10px 20px;
  }
 
}


@media (max-width: 685px) {

  /* .Glass {
    overflow-y: scroll;
  } */
  .Glass h1 {
    margin-top: 20px;
    color: white;
    /* font-size: 30px; */
    align-items: last baseline;
  }
  .Glass p {
    color: white;
    /* font-size: 15px; */
    align-items: last baseline;
    margin: 2% 2%;
  }
  .front {
    padding: 5px 30px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 8px 10px;
  }
  
}
@media (max-width: 613px) {

  /* .Glass {
    overflow-y: scroll;
  } */

  .Glass {
    align-items: center;
  }
  .Glass h1 {
    margin-top: 20px;
    color: white;
    font-size: 15px;
    align-items: center;
  }
  .Glass p {
    color: white;
    font-size: 10px;
    text-align: center;
    margin: 2% 2%;
  }
  .star {
    margin-top: -30%;
  }
  .star1 {
    margin-top: -14%;
    left: 0;
  }
}

@media (max-width: 575px) {

  .Glass {
    margin-top: 25%;
  }
  .front {
    padding: 2px 20px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 5px 6px;
  }
  .spacing {
    margin-right: 30px;
  }
}

@media (max-width: 510px) {

  .Glass {
    margin-top: 28%;
  }
  
  .Glass h1 {
    margin-top: 20px;
    color: white;
    /* font-size: 20px; */
    text-align: center;
  }
  .Glass p {
    color: white;
    /* font-size: 14px; */
    text-align: center;
    margin: 2% 2%;
  }

  .front {
    padding: 10px 30px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 12px 30px;
  }
  .thumb {
    width: 300px;
  }
  .logo {
    width: 50px;
    height: auto;
    margin-top: 10px;
  }
}

@media (max-width: 433px) {

  .Glass {
    margin-top: 30%;
  }
  .rocketlogo {
    position: absolute;
    bottom: -85px; /* Adjust as needed */
    right: -20px; /* Adjust as needed */
    width: 60px;
  }
}


@media (max-width: 420px) {
  .App {      
    /* background-image: url("../assets/love/phone.png"); */
  }
}

.container-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  align-items: center;
  margin-bottom: 30px;
}
.container-1 > img {
  max-width: 85px;
}
.container-1 > span {
  margin: -15px 0 5px 0;
  font-size: 17px;
}
.container-1 > a {
  font-size: 21px;
  font-weight: 600;
  max-width: 60%;
  text-align: center;
  color: black;
}
.container-1 > button {
  font-size: 23px;
  font-weight: 800;
  margin-top: 18px;
  padding: 15px 50px;
  background-color: #1b1b1b;
  color: #fff;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
}
.container-2 {
  background-color: #fff;
  position: relative;
}

/* .embeded{
  width: auto;
} */

.lottie-container {
  position: absolute;
  top: 30%;
}

.SaveUserCredentials_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.SaveUserCredentials_form > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.SaveUserCredentials_form > form > .button_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SaveUserCredentials_form > form > .button_container > button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SaveUserCredentials_form > form > div {
  display: flex;
  flex-direction: column;
}

.error_message {
  color: red;
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}

@media (max-width: 340px) {
  .front {
    padding: 8px 20px;
    border-radius: 12px;
    font-size: 1.45rem;
  }
  .continueButton {
    padding: 8px 10px;
  }
}

@media (max-width: 320px) {
  .container-1 > img {
    max-width: 55px;
  }
  .container-1 > a {
    max-width: 100%;
  }
  .container-1 > button {
    font-size: 14px;
    font-weight: 800;
    margin-top: 18px;
    padding: 15px 40px;
    background-color: #1b1b1b;
    color: #fff;
    border: 0;
    border-radius: 15px;
    cursor: pointer;
  }
}
